  input {
    display: block;
    width: 100%;
  }
  .d-flex {
    display: flex !important;
  }
  
  .justify-content-end {
    justify-content: flex-end !important;
  }
  
  .justify-content-between {
    justify-content: space-between !important;
  }
  .input__error input {
    border-color: red;
  }
  .error__feedback {
    color: red !important;
    margin-bottom: 0;
  }
.ant-steps {
    width: 80%;
    margin: 25px auto 25px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #6cbc33;
    border-color: #6cbc33;
}

.ant-steps .ant-steps-item-icon {
    width: 28px;
    height: 28px;    
    line-height: 28px;
}

button.ant-btn.css-dev-only-do-not-override-dfjnss.ant-btn-primary {
    height: 50px;
    width: 200px;
    background: #72BD0A;
    box-shadow: 1px 1px 15px rgb(0 0 0 / 15%);
    border-radius: 10px;
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    line-height: 40px;
}

button.ant-btn.css-dev-only-do-not-override-dfjnss.ant-btn-default {

    height: 50px;
    width: 200px;
    background: #72BD0A;
    box-shadow: 1px 1px 15px rgb(0 0 0 / 15%);
    border-radius: 10px;
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    line-height: 40px;
}

.ant-steps .ant-steps-item-icon .ant-steps-icon {
    top: -1.5px;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #65bb46;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #65bb4642;
}

.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #6cbc33;
}

.add_img_uplod {
    margin-bottom: 10px;
}

.add_img_uplod img {
    width: 100%;
    box-shadow: 1px 3px 20px rgb(0 0 0 / 15%);
    padding: 5px;
    border-radius: 20px;
}

.add_img_uplod h1 {
    text-align: center;
    color: #72BD0A;
    margin-bottom: 0;
    padding: 25px 0 35px;
}

.add_img_uplod h1 {
    text-align: center;
    color: #72BD0A;
    margin-bottom: 0;
    padding: 25px 0 35px;
}

.add_img_uplod h1 p {
    color: #72BD0A;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
}

a.btn.profilee {
    height: 50px;
    width: 200px;
    background: #72BD0A;
    box-shadow: 1px 1px 15px rgb(0 0 0 / 15%);
    border-radius: 10px;
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-top: 30px;
    line-height: 40px;
    text-align: center;
}

.gray_box_family {
    background: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
}

.gray_box_family p {
    margin-bottom: 0;
    color: #828282;
    font-size: 14px;
    font-weight: 500;
}